import React, { Component } from 'react'
import styled from 'styled-components'
import Disqus from 'disqus-react'

const CommentsWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`

const ToggleComments = styled.button`
  display: block;
  margin: 15px auto;
  padding: 20px;
  border-radius: 5px;
  background: ${props => props.theme.colors.base};
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 300;
`

class Comments extends Component {
  constructor(props) {
    super(props)
    this.state = { showComments: false }
  }
  toggleComments = () => {
    this.setState(prevState => ({
      showComments: !prevState.showComments,
    }))
  }
  render() {
    const disqusShortname = 'kindredstyles'
    const disqusConfig = {
      url: this.props.url,
      identifier: this.props.id,
      title: this.props.title,
    }
    return (
      <div>
        {this.state.showComments ? (
          <CommentsWrapper>
            <ToggleComments onClick={this.toggleComments}>
              Hide Comments
            </ToggleComments>
            <Disqus.DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </CommentsWrapper>
        ) : (
          <CommentsWrapper>
            <ToggleComments onClick={this.toggleComments}>
              Share Your Thoughts
            </ToggleComments>
          </CommentsWrapper>
        )}
      </div>
    )
  }
}

export default Comments
