import React, { Component } from 'react'
import Comments from '../components/Comments'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPinterestP,
  faTwitter,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons'
import { faCopy, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const ShareSection = styled.div`
  padding: 3rem 0 2rem 0;
  h4 {
    font-family: 'Signature', cursive;
    font-size: 3rem;
    text-align: center;
    margin: 0 0 3rem 0;
  }
  textarea {
    position: absolute;
    left: -999px;
  }
  .copy {
    cursor: pointer;
    .copy-icon {
      display: block;
    }
    .success-icon {
      display: none;
    }
  }

  .success {
    cursor: pointer;
    .copy-icon {
      display: none;
    }
    .success-icon {
      display: block;
      color: green;
    }
  }
`

const SocialShare = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  width: 75%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  justify-items: center;
  font-size: 25px;
  align-content: center;
  a {
    margin: 0 15px;
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
  }
`

class Share extends Component {
  constructor(props) {
    super(props)

    this.state = { copyClasses: false }
  }

  copyToClipboard = e => {
    this.textArea.select()
    document.execCommand('copy')
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus()
    this.setState({ copyClasses: true })
    setTimeout(
      function() {
        this.setState({ copyClasses: false })
      }.bind(this),
      3000
    )
  }
  render() {
    const { title, id, url, imageUrl } = this.props
    let copyClassName
    if (this.state.copyClasses === false) {
      copyClassName = 'copy'
    } else {
      copyClassName = 'success'
    }
    return (
      <div>
        <hr />
        <ShareSection>
          <h4>Sharing Is Caring!</h4>
          <textarea
            value={url}
            readOnly
            ref={textarea => (this.textArea = textarea)}
            type="hidden"
            aria-label="hidden"
          />
          <SocialShare>
            <button
              onClick={this.copyToClipboard}
              className={copyClassName}
              aria-label="Copy post url to clipboard"
              type="button"
            >
              <FontAwesomeIcon icon={faCopy} className="copy-icon" />
              <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
            </button>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.pinterest.com/pin/create/button/?url=${url}&media=${imageUrl}&description=${encodeURIComponent(
                title
              )}`}
              aria-label="Pinterest share"
            >
              <FontAwesomeIcon icon={faPinterestP} />
            </a>
            <a
              href="https://twitter.com/share?ref_src=twsrc%5Etfw"
              aria-label="Twitter share"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Facebook share"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </SocialShare>
          <Comments title={title} id={id} url={url} />
        </ShareSection>
        <hr />
      </div>
    )
  }
}

export default Share
