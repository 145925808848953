import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 0 auto 2em;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

const Date = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 300;
  letter-spacing: 2px;
`

const PostDate = props => {
  return (
    <Wrapper>
      <Date>{props.date}</Date>
    </Wrapper>
  )
}

export default PostDate
