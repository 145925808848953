import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPinterest } from '@fortawesome/free-brands-svg-icons'

export const PinLink = styled.a`
  position: absolute;
  left: 10px;
  top: 10px;
  width: auto;
  z-index: 996;
  visibility: hidden;
`
const PinContainer = styled.div`
  position: relative;
  width: 100%;
`

const Pin = ({ slug, url, title }) => {
  return (
    <PinContainer>
      <PinLink
        rel="noopener noreferrer"
        target="_blank"
        href={`https://www.pinterest.com/pin/create/button/?url=kindredstyles.com/${slug}&media=${url}&description=${encodeURIComponent(
          title
        )}`}
      >
        <FontAwesomeIcon
          icon={faPinterest}
          style={{
            background: 'white',
            color: '#E60023',
            fontSize: '2.5rem',
            borderRadius: '2.5rem',
          }}
        />
      </PinLink>
    </PinContainer>
  )
}

export default Pin
