import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import PostLinks from '../components/PostLinks'
import TagList from '../components/TagList'
import Share from '../components/Share'

const PostTemplate = ({ data, pageContext, location }) => {
  const {
    id,
    slug,
    title,
    category,
    featuredImage,
    content,
    publishedDate,
    tag,
    metaDescription,
  } = data.contentfulBlogPost
  const { href } = location
  const previous = pageContext.prev
  const next = pageContext.next

  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
        <meta name="description" content={metaDescription.internal.content} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@kindred_styles" />
        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:description"
          content={metaDescription.internal.content}
        />
        <meta name="twitter:creator" content="@kindred_styles" />
        <meta name="twitter:image:src" content={featuredImage.file.url} />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${config.siteUrl}${slug}`} />
        <meta property="og:image" content={featuredImage.file.url} />
        <meta
          property="og:description"
          content={metaDescription.internal.content}
        />
        <meta property="og:site_name" content="Kindred Styles" />
        <meta property="article:published_time" content={publishedDate} />
        <meta property="article:section" content={category} />
        <meta property="article:tag" content={tag} />
        <meta property="fb:admins" content="Facebook numberic ID" />
      </Helmet>
      <Container>
        <Hero
          title={title}
          image={featuredImage}
          publishedDate={publishedDate}
          category={category}
          slug={slug}
        />
        <PageBody body={content} />
        {tag && <TagList tags={tag} />}
        <PostLinks previous={previous} next={next} />
        <Share
          title={title}
          id={id}
          url={href}
          imageUrl={featuredImage.file.url}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      category {
        categoryName
        slug
      }
      metaDescription {
        internal {
          content
        }
      }
      publishedDate(formatString: "MMMM DD, YYYY")
      publishedDateISO: publishedDate(formatString: "YYYY-MM-DD")
      tag
      featuredImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default PostTemplate
